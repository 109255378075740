@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "TitilliumWeb";
  src: local("TitilliumWeb"),
   url("./fonts/TitilliumWeb-Regular.ttf");
  font-weight: regular;
 }

body {
  margin: 0;
  font-family: 'TitilliumWeb';
}

.start {
  background-color: white;
  background-size: cover;
}

.footer {
  background: url('./images/img/tło01.png');
  background-size: cover;
}


